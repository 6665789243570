// Styling for Activity Tile card tag tooltip that is placed at body to be able to overflow.
$tooltip-arrow-width: 20px;
$tooltip-arrow-height: 10px;
$tooltip-max-width: 252px;
$tooltip-color: white;
$tooltip-bg: #2E2E2E;
$tooltip-border-radius: 3px;
$tooltip-padding-x: 16px;
$tooltip-padding-y: 16px;
$tooltip-font-size: 14px;

@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules//bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/tooltip";


.tooltip-inner {
  text-align: left;

  .tooltip-container .tooltip-title {
    font-weight: 900;
  }
}
